import React from 'react'
import PropTypes from 'prop-types'

const Dolphin = (props) => {
	const { color, width, height, ...otherProps } = props
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="dolphin-logo"
			viewBox="0 0 137 64"
			fill="currentColor"
			role="img"
			aria-label="Dolphin Logo"
			width={width ? width : ''}
			height={height ? height : ''}
			{...otherProps}
		>
			<g id="tm">
				<polygon
					id="T"
					fillRule="evenodd"
					fill={color}
					points="129.11,36.33 130.17,36.33 130.17,40.13 131.12,40.13 131.12,36.33
			132.18,36.33 132.18,35.58 129.11,35.58 	"
				/>
				<polygon
					id="M"
					fillRule="evenodd"
					fill={color}
					points="135.62,35.58 134.86,38.67 134.85,38.67 134.13,35.58 132.6,35.58
			132.6,40.13 133.53,40.13 133.53,36.62 133.54,36.62 134.38,40.13 135.28,40.13 136.16,36.62 136.17,36.62 136.17,40.13
			137.1,40.13 137.1,35.58 	"
				/>
			</g>
			<path
				id="shadow"
				fill={color}
				d="M126.53,39.34c0.02-1.02-0.14-2.04-0.48-3c-0.11-0.28-0.25-0.55-0.42-0.8
		c-0.83-1.52-2.35-2.45-5-2.45c-1.75-0.06-3.42,0.76-4.45,2.18c0-0.08,0-0.85,0-1.42s-0.17-0.61-0.64-0.62h-6.49
		c0.81-0.8,1.31-1.9,1.31-3.12c0-2.42-1.97-4.39-4.39-4.39c-1.05,0-2,0.38-2.75,0.99l0.15-0.24c1.75-2.63,1.3-6.48-0.31-9
		c-1.01-1.74-2.66-3.02-4.6-3.55c-1.83-0.45-3.74-0.39-5.54,0.16c-3.17,1.02-5.71,3.41-6.92,6.51l-0.21,0.63
		c-0.17,0.48-0.3,0.97-0.37,1.47c0,0.14,0,0.29,0,0.44h-0.09c-0.46-0.19-0.9-0.43-1.3-0.72L83.9,22.3c-0.59-0.49-1.1-1.06-1.53-1.7
		c-3.11-4.88-6-10.09-10-14.31c-2.21-2.5-4.94-4.48-8-5.8c-1.86-0.62-3.87-0.62-5.73,0c-1.21,0.3-2.36,0.78-3.43,1.41
		c-1.37,0.9-2.62,1.97-3.72,3.19c-1.12,1.29-2.13,2.67-3,4.14c-2.79-2-6-4.25-9.54-4.52h-0.13c-2.06-0.21-4.15,0.18-6,1.11
		c-3,1.69-5,4.52-7.16,7.09c-0.52,0.72-1.11,1.39-1.75,2c-2.32-0.89-4.78-1.35-7.26-1.36c-3.13,0.17-6.17,1.08-8.87,2.66
		c-0.66,0.4-1.29,0.85-1.89,1.35c-0.3,0.25-0.59,0.52-0.88,0.79c-0.58,0.56-1.13,1.15-1.65,1.77c-1.97,2.67-3.09,5.87-3.24,9.18
		C0,30.2,0,31.12,0,32c0,1.79,0.18,3.58,0.55,5.34C1.12,40.05,2.3,42.6,4,44.78c0.5,0.63,1.03,1.22,1.6,1.79
		c2.21,2.31,4.98,3.99,8.05,4.89c1.68,0.46,3.39,0.82,5.11,1.08c-0.63,3.51-1.56,6.97-2.76,10.33l0.05,0.09l0.08,0.08
		c9.72,0.64,23.54-0.94,32.35-6.62c1.53,0.36,3.08,0.61,4.64,0.74c1.09,0.06,2.19,0.06,3.28,0c0.98-0.02,1.95-0.13,2.91-0.31V64
		c0,0.12,0,0.18,0.06,0.24s0.13,0.07,0.25,0.07h7.53c0.04-0.05,0.11-0.08,0.18-0.07l8.43-9c3.59-0.12,8.2-0.18,11.86-0.12
		c3.66,0.06,7.43,0.31,11.08,0.68c3.65,0.37,7.43,0.81,11.15,1.36c3.72,0.55,6.47,1.37,10.18,2.17h0.13
		c0.06-0.06,0.12-0.06,0.18-0.12l6.23-6.92V40.72C126.57,40.25,126.56,39.79,126.53,39.34z"
			/>
			<g id="dolphin">
				<path
					id="D"
					fill="#FFFFFF"
					d="M38.75,32.75c-2.27-1.9-5.47-2.26-8.33-2.18c-3.88,0.1-8.3,1.38-10.73,4.65
			c-1.3,1.56-1.55,3.75-0.65,5.57c0,0,0.18-2.48,1.71-2.74c0.8-0.14,1.23,0.84,1.4,1.42c0.77,2.87,1.06,5.84,0.85,8.8
			c-0.08,1.47-0.25,2.94-0.5,4.4c-0.06,0.33-0.31,1.74-0.47,2.66c2.73-0.14,5.45-0.47,8.13-1c5.84-0.99,12.51-5.51,12.5-13.08
			c-0.01-2.33-0.75-4.6-2.1-6.5C40.05,34,39.44,33.33,38.75,32.75z M29.4,49.5c0,0-1.41,0.22-2,0.27c0.1-0.5,0.32-3.02,0.32-3.34
			c0.09-1.48,0.09-2.96,0-4.44c-0.02-0.3-0.1-0.61-0.1-0.92c-0.11-1-0.2-2-0.33-3c-0.02-0.22-0.14-0.95-0.14-1.11
			c-0.04-0.58,0.5-0.82,0.91-1c0.52-0.23,1.05-0.41,1.59-0.56c1.54-0.48,3.19-0.4,4.67,0.24c2.2,1.09,3.08,3.68,3.08,6
			C37.4,46.12,34.73,48.77,29.4,49.5z"
				/>
				<path
					id="o"
					fill="#FFFFFF"
					d="M52.59,34.43c-4.51,0-8.12,3-8.12,7.67s3.61,7.67,8.12,7.67c4.51,0,8.13-3,8.13-7.67
			S57.1,34.43,52.59,34.43z M52.59,45.8c-0.16,0.01-0.33,0.01-0.49-0.01c-1.88-0.16-3.28-1.81-3.12-3.69c0-1.99,1.62-3.61,3.61-3.61
			c1.99,0,3.61,1.62,3.61,3.61c0.01,0.16,0.02,0.33,0.01,0.49C56.09,44.47,54.48,45.91,52.59,45.8z"
				/>
				<rect id="l" x="62.65" y="25.42" fill="#FFFFFF" width="4.51" height="23.07" />
				<path
					id="p"
					fill="#FFFFFF"
					d="M78,31.91c-1.77,0.02-3.42,0.86-4.48,2.28h-0.06v-1.92H69.3v21.48h4.52v-8.42h0.06
			c1.14,1.35,2.87,2.07,4.63,1.92c4.15,0,6.68-3.73,6.68-7.79C85.19,35.04,82.33,31.91,78,31.91z M77.07,43.27v0.01
			c-0.17,0.01-0.33,0.01-0.5-0.01c-1.88-0.16-3.27-1.82-3.11-3.7c0-1.99,1.62-3.61,3.61-3.61s3.61,1.62,3.61,3.61
			c0.01,0.16,0.02,0.33,0.01,0.49C80.57,41.94,78.96,43.38,77.07,43.27z"
				/>
				<path
					id="h"
					fill="#FFFFFF"
					d="M95.76,32.86c-1.71-0.12-3.33,0.8-4.1,2.34H91.6V25.09h-4.51v22.75h4.51v-7.28
			c0-1.9,0.36-3.73,2.71-3.73s2.35,2.17,2.35,3.85v7.48l4.51,0.32v-8.7C101.17,35.86,100.33,32.86,95.76,32.86z"
				/>
				<g id="i">
					<polygon fill="#FFFFFF" points="103.79,48.78 108.3,49.1 108.3,34.48 103.79,34.48 		" />
					<circle fill="#FFFFFF" cx="106.05" cy="29.87" r="2.62" />
				</g>
				<path
					id="n"
					fill="#FFFFFF"
					d="M119.27,35.07c-1.75-0.07-3.39,0.83-4.27,2.34h-0.06v-2h-4.34l0,13.95l4.52,0.64v-7.25
			c0-1.9,0.37-3.73,2.71-3.73c2.34,0,2.34,2.17,2.34,3.85V51l4.52,1V41.99C124.69,38.07,123.85,35.07,119.27,35.07z"
				/>
			</g>
			<path
				id="wave"
				fill="#FFFFFF"
				d="M102.74,20.81c-0.09-0.52-0.26-1.02-0.5-1.49c-0.16-0.38-0.35-0.75-0.56-1.11
		c-0.91-1.39-2.3-2.41-3.9-2.85c-1.01-0.29-2.07-0.36-3.11-0.21c-2.8,0.42-5.26,2.1-6.67,4.55c-0.57,1.16-0.91,2.42-1,3.71L86.87,25
		c-1.44,0.01-2.83-0.51-3.9-1.46l-0.06-0.06c-1.47-1.35-2.7-2.94-3.66-4.69c-1.29-2.05-2.51-4.16-3.89-6.16
		c-1.96-3.01-4.36-5.71-7.13-8c-0.53-0.44-1.08-0.83-1.67-1.18c-0.88-0.67-1.86-1.19-2.91-1.55c-0.5-0.12-0.93-0.25-1.36-0.31
		c-1.43-0.09-2.87,0.12-4.22,0.62c-0.43,0.11-0.84,0.28-1.23,0.5c-0.74,0.38-1.43,0.86-2.05,1.42C54.51,4.39,54.26,4.68,54.05,5
		c1.47-0.38,3.03-0.24,4.4,0.42c1.44,0.9,2.26,2.52,2.16,4.21c0.07,1.8-1.11,3.1-3.46,3.9c-2.35,0.87-4.98,0.49-7-1
		c-0.73-0.71-1.52-1.35-2.36-1.92l-0.06-0.06c-0.41-0.34-0.85-0.65-1.3-0.93c-0.72-0.49-1.46-0.94-2.23-1.36
		c-0.48-0.33-1-0.6-1.55-0.8c-0.43-0.29-0.91-0.5-1.42-0.62C40.44,6.5,39.6,6.3,38.75,6.22c-1.57-0.15-3.16,0.06-4.64,0.62
		C33.66,7,33.24,7.23,32.87,7.52C32.23,7.91,31.66,8.41,31.2,9c-0.51,0.67-0.92,1.42-1.2,2.21c1.27-1.18,3.07-1.6,4.74-1.11
		c1.67,0.5,2.99,1.8,3.53,3.46c0.66,1.68,0.3,3.58-0.93,4.9c-0.44,0.46-0.91,0.87-1.43,1.24c-1.36,0.82-2.99,1.05-4.52,0.61
		l-0.55-0.18c-1.18-0.4-2.3-0.95-3.35-1.61c-1.79-0.99-2.97-1.61-3.53-1.86c-0.18-0.06-0.25-0.12-0.43-0.19
		c-0.72-0.32-1.47-0.59-2.23-0.8c-2.15-0.63-4.43-0.73-6.63-0.31L13,15.73c-1.54,0.54-3.02,1.25-4.41,2.11
		c-1.24,0.81-2.32,1.82-3.21,3c-0.28,0.36-0.53,0.74-0.76,1.14c-0.42,0.72-0.75,1.48-1,2.27c-0.15,0.48-0.27,0.96-0.37,1.45
		c-0.29,1.63-0.22,3.29,0.2,4.89c0.33,1.14,0.9,2.19,1.69,3.07c0.9,1.03,1.91,1.95,3,2.77c1.32,1.01,2.73,1.87,4.23,2.58
		c0.86,0.41,1.74,0.78,2.63,1.13l1.38,0.52l0.72,0.26c0.11,0.04,0.72,0.16,0.72,0.25c-1.15-2.63-0.64-5.7,1.3-7.82
		c2.3-2.46,5.43-3.56,8.67-4.13c1.23-0.2,2.47-0.27,3.71-0.22c2.12,0.07,4.21,0.57,6.13,1.46c0.86,0.4,1.66,0.92,2.37,1.54
		c0.5,0.43,0.97,0.88,1.41,1.36c0.33,0.35,0.71,1.18,1.27,0.64c1.32-1.24,2.31-2.62,3.72-3.84c1.41-1.22,2.9-2.36,4.41-3.47
		c2.19-1.63,4.67-2.83,7.3-3.53c1.63-0.46,3.31-0.67,5-0.62c2.75,0.13,5.46,0.74,8,1.81l6.67,2.38l3.7,1.33c0,0,3.9,1.02,5.08,1.23
		c4.14,0.32,4.61,0.32,4.61,0.32l2.45-0.07c1.81,0.07,3.6-0.34,5.2-1.17c0.34-0.19,0.68-0.39,1-0.6c0.24-0.15,0.47-0.32,0.69-0.5
		c0.53-0.42,1-0.93,1.37-1.5c0.2-0.32,0.37-0.65,0.52-1C102.86,23.5,102.97,22.14,102.74,20.81z M98,24.4
		c-0.47,0.47-1.02,0.84-1.63,1.1c-0.88,0.25-1.79,0.42-2.7,0.5h-1.83c-0.06-1.28,0-2.56,0.17-3.83c-0.02-0.22,0.03-0.44,0.15-0.62
		c0.12-0.61,0.45-1.16,0.94-1.55c0.67-0.59,1.52-0.91,2.41-0.92h0.22c0.85,0.01,1.66,0.34,2.27,0.92c0.2,0.15,0.38,0.33,0.53,0.53
		C99.45,21.75,99.22,23.48,98,24.4z"
			/>
		</svg>
	)
}

Dolphin.propTypes = {
	color: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
}
Dolphin.defaultProps = {
	color: '#353E47',
	width: '',
	height: '',
}

export default Dolphin
