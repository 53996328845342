import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BLOCKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import {
	WhichModelSection,
	WhichModelContainer,
	WhichModelP,
	WhichModelTextContainer,
	WhichModelHook,
	WhichModelSubtext,
	WhichModelDescription,
	WhichModelButton,
	Italic,
} from './styles'

const WhichModel = (): JSX.Element => {
	const { whichmodel, whichmodelXl, whichmodelLg, whichmodelBase } = useStaticQuery(graphql`
		{
			whichmodel: allContentfulBanner(filter: { contentful_id: { eq: "5YIiVAvRGqzyN2zXXlmWGv" } }) {
				edges {
					node {
						hook
						subtext
						node_locale
						mainActionText
						mainActionUrl
						description {
							raw
						}
						backgroundImage {
							fluid(maxWidth: 1920, maxHeight: 900, quality: 80, cropFocus: BOTTOM, resizingBehavior: FILL) {
								src
								srcWebp
								base64
							}
						}
					}
				}
			}
			whichmodelXl: allContentfulBanner(filter: { contentful_id: { eq: "5YIiVAvRGqzyN2zXXlmWGv" } }) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(maxWidth: 1440, maxHeight: 750, quality: 80, cropFocus: BOTTOM, resizingBehavior: FILL) {
								src
								srcWebp
								base64
							}
						}
					}
				}
			}
			whichmodelLg: allContentfulBanner(filter: { contentful_id: { eq: "5YIiVAvRGqzyN2zXXlmWGv" } }) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(maxWidth: 1440, maxHeight: 750, quality: 80, cropFocus: BOTTOM, resizingBehavior: FILL) {
								src
								srcWebp
								base64
							}
						}
					}
				}
			}
			whichmodelBase: allContentfulBanner(filter: { contentful_id: { eq: "5YIiVAvRGqzyN2zXXlmWGv" } }) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(maxWidth: 1280, maxHeight: 650, quality: 80, cropFocus: BOTTOM, resizingBehavior: FILL) {
								src
								srcWebp
								base64
							}
						}
					}
				}
			}
		}
	`)

	const [
		{
			node: { hook, subtext, backgroundImage, description, mainActionText, mainActionUrl },
		},
	] = whichmodel.edges
	const [{ node: whichmodelxl }] = whichmodelXl.edges
	const [{ node: whichmodellg }] = whichmodelLg.edges
	const [{ node: whichmodelbase }] = whichmodelBase.edges

	const option = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: function Paragraph(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <WhichModelP>{children}</WhichModelP>
			},
		},
	}

	const sources = [
		{
			...backgroundImage.fluid,
			srcSet: `${backgroundImage.fluid.src} 1920w`,
			srcSetWebp: `${backgroundImage.fluid.srcWebp} 1920w`,
			media: `(min-width: 1601px)`,
		},
		{
			...whichmodelxl.backgroundImage.fluid,
			srcSet: `${whichmodelxl.backgroundImage.fluid.src} 1024w`,
			srcSetWebp: `${whichmodelxl.backgroundImage.fluid.srcWebp} 1024w`,
			media: `(min-width: 1024px) and (max-width: 1349px)`,
		},
		{
			...whichmodellg.backgroundImage.fluid,
			srcSet: `${whichmodellg.backgroundImage.fluid.src} 1024w`,
			srcSetWebp: `${whichmodellg.backgroundImage.fluid.srcWebp} 1024w`,
			media: `(min-width: 1024px) and (max-width: 1349px)`,
		},
		{
			...whichmodelbase.backgroundImage.fluid,
			srcSet: `${whichmodelbase.backgroundImage.fluid.src} 768w`,
			srcSetWebp: `${whichmodelbase.backgroundImage.fluid.srcWebp} 768w`,
			media: `(min-width: 768px) and (max-width: 1023px)`,
		},
	]

	const newhook = hook.split(' ')
	const findindex = newhook.indexOf('which')
	const x = <Italic>{newhook[findindex]}</Italic>
	newhook.splice(findindex, 1, x)
	newhook.map((el: React.ReactElement | string, i: number) => {
		if (typeof el === 'string') {
			newhook.splice(i, 1, `${el} `)
		} else {
			newhook.splice(i + 1, 0, ' ')
		}
	})

	return (
		<WhichModelSection sources={sources}>
			<WhichModelContainer sources={sources}>
				<WhichModelTextContainer>
					<WhichModelHook>{newhook}</WhichModelHook>
					<WhichModelSubtext>{subtext}</WhichModelSubtext>
					<WhichModelDescription>
						{documentToReactComponents(JSON.parse(description.raw), option)}
						<WhichModelButton to={mainActionUrl}>{mainActionText}</WhichModelButton>
					</WhichModelDescription>
				</WhichModelTextContainer>
			</WhichModelContainer>
		</WhichModelSection>
	)
}

export default WhichModel
