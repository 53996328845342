import React from 'react'
import tw from 'twin.macro'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Featured from '../../../sea-site/src/components/home/featured'
import WhichModel from '../../../sea-site/src/components/home/which-model'
import SupportContact from '../../../sea-site/src/components/home/support-contact'
import News from '../../../sea-site/src/components/home/news'

export const IndexPage = (): JSX.Element => {
	const Section = tw.section`bg-gray-900 lg:overflow-hidden`
	return (
		<Layout>
			<SEO
				title="Dolphin Robotic Pool Cleaner"
				description={`Maytronics ${process.env.GATSBY_COUNTRY}, the home of the Dolphin Robotic Pool Cleaner`}
				keywords={[`maytronics`, `maytronics ${process.env.GATSBY_COUNTRY}`, `home`]}
			>
				<link key={'es-ES'} rel="alternate" href="https://www.maytronics.com.es/" hrefLang="es-ES" />
				<link key={'it-IT'} rel="alternate" href="https://www.maytronics.it/" hrefLang="it-IT" />
				<link key={'he-IL'} rel="alternate" href="https://www.maytronics.co.il/" hrefLang="he-IL" />
				<link key={'de-DE'} rel="alternate" href="https://www.maytronics.com.de/" hrefLang="de-DE" />
				<link key={'fr-FR'} rel="alternate" href="https://www.robot-dolphin.fr/" hrefLang="fr-FR" />
				<link key={'en-US'} rel="alternate" href="https://maytronicsus.com/" hrefLang="en-US" />
				<link key={'en-CA'} rel="alternate" href="https://maytronicsus.com/" hrefLang="en-CA" />
				<link key={'de-AT'} rel="alternate" href="https://www.maytronics.com.de/" hrefLang="de-AT" />
				<link key={'en-AU'} rel="alternate" href="https://maytronics.com.au/" hrefLang="en-AU" />
				<link key={'en-NZ'} rel="alternate" href="https://maytronics.co.nz/" hrefLang="en-NZ" />
				<link key={'en-ZA'} rel="alternate" href="https://maytronics.co.za/" hrefLang="en-ZA" />
				<link key={'en-SG'} rel="alternate" href="https://maytronics.com.sg/" hrefLang="en-SG" />
				<link key={'th-TH'} rel="alternate" href="https://maytronics-thailand.com/" hrefLang="th-TH" />
				<link key={'ms-MY'} rel="alternate" href="https://maytronics.com.my/" hrefLang="ms-MY" />
				<link key={'en-PH'} rel="alternate" href="https://maytronics.com.ph/" hrefLang="en-PH" />
				<link key={'x-default'} rel="alternate" href="https://www.maytronics.com/" hrefLang="x-default" />
			</SEO>
			{/** Home Banner */}
			<Section id="home-banner">
				<div tw="lg:grid lg:grid-cols-2">
					<div tw="bg-gradient-to-r from-gray-800 to-blue-700 sm:px-6 sm:text-center lg:px-4 lg:text-left lg:flex lg:items-center">
						<div tw="py-12 lg:py-24 mx-auto max-w-md px-4 sm:max-w-2xl">
							{/* <Link
								to="#"
								tw="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
							>
								<span tw="w-5 h-5 bg-gradient-to-r from-yellow-500 to-yellow-700 rounded-full" />
								<span tw="ml-4 text-sm">Next</span>
								* Heroicon name: chevron-right
								<svg
									tw="ml-2 w-5 h-5 text-yellow-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
										clipRule="evenodd"
									/>
								</svg>
							</Link> */}
							<h2 tw="mt-4 text-2xl tracking-tight font-display font-semibold text-white sm:mt-5 sm:text-4xl lg:mt-5 xl:text-5xl">
								<span tw="block">Discover Your Exceptional</span>
								<span tw="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-blue-500 block">
									Experience with Maytronics
								</span>
							</h2>
							<p tw="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
								At Maytronics we are passionate about leading the way to make your pool water exceptional, for your pure
								enjoyment.
							</p>
							<div tw="mt-10 sm:mt-12 flex items-center space-x-2">
								<Link
									to="/dolphin"
									tw="inline-flex items-center md:mr-3 py-3 px-6 rounded-md shadow border-2 border-yellow-600 bg-gradient-to-r from-yellow-500 to-yellow-700 text-gray-800 text-center font-medium hover:from-yellow-400 hover:to-yellow-600 focus:border-gray-600 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-yellow-300"
								>
									POOL CLEANING
								</Link>
								{/* <Link
									to="#"
									tw="inline-flex items-center py-3 px-6 rounded-md shadow border-2 border-blue-300 text-blue-200 text-center font-medium hover:bg-blue-200 hover:border-blue-200 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:border-blue-700 focus:ring-blue-200"
								>
									WATER TREATMENT
								</Link> */}
							</div>
						</div>
					</div>
					<div
						tw="lg:mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative bg-cover bg-right bg-no-repeat"
						css={{
							backgroundImage:
								'url("https://images.ctfassets.net/ftcnv5up9dxv/xMuFcLZyO2OQnihlJYCQD/5169944aa34214aa74e45b711573f233/BrandImage_HR_Dolphin_M600_CleaningWaterline_01_WithLogo.jpg?w=600&h=600&q=60&fit=fill&f=right")',
						}}
					>
						<div tw="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
							<img
								tw="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none opacity-0"
								src="https://images.ctfassets.net/ftcnv5up9dxv/xMuFcLZyO2OQnihlJYCQD/5169944aa34214aa74e45b711573f233/BrandImage_HR_Dolphin_M600_CleaningWaterline_01_WithLogo.jpg?w=600&h=600&q=60&fit=fill&f=right"
								alt="The Dolphin M600 climbing and scrubbing a pools pebble wall"
							/>
						</div>
					</div>
				</div>
			</Section>
			<Featured />
			<WhichModel />
			<SupportContact />
			<News />
		</Layout>
	)
}

export default IndexPage
