interface Items {
	name: string
	id: string
	creative: string
	position: string
}

declare global {
	interface Window {
		dataLayer: {
			event: string
			ecommerce: {
				promoView?: {
					promotions: { [field: string]: string }[]
				}
				promoClick?: {
					promotions: Items[]
				}
				currencyCode?: string
				impressions?:
					| {
							name?: string
							sku?: string
							price?: number
							brand?: string
							category?: string
							list?: string
							position?: number
							id?: string
					  }[]
					| undefined
					| boolean
				click?: {
					actionField: {
						list: string
					}
					products: [
						{
							name: string
							id: string
							price: number
							brand: string
							category: string
							position: number
						}
					]
				}
				detail?: {
					actionField: {
						list: string
					}
					products: {
						name: string
						id: string
						price: number
						brand: string
						category: string
					}[]
				}
			}
			eventCallback?: () => string | undefined
		}[]
	}
}

interface Prod {
	name: string
	seoTitle?: string
	list: string
	position: number
	price: number
	sku: string
	brand: string
	categories?: {
		slug: string
		title: string
	}
	category?: string
}
const floatPrice = (price: number) =>
	price >= 1 ? (typeof price != 'number' ? parseFloat(price).toFixed(2) : price.toFixed(2)) : parseFloat('0').toFixed(2)
/**
 * Call this function when a user clicks on a product link. This function uses the event
 * callback datalayer variable to handle navigation after the ecommerce data has been sent
 * to Google Analytics.
 * @param {object} product An object representing a product.
 * @param {string} list The listing type e.g. 'Search Results'.
 * @param {number} index The position of the product.
 * @param {Function} callback The navigational function called after event has sent.
 */

export const onProductClick = (product: Prod, list: string, index: number, callback: boolean | Promise<void>): void => {
	const price = Number(floatPrice(product.price))
	const category = [(product.categories && product.categories.title) || product.category].toString()
	window &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'productClick',
			ecommerce: {
				click: {
					actionField: {
						list: list, // The list of the product that was clicked
					},
					products: [
						{
							name: product.seoTitle || product.name, // Name or ID is required.
							id: product.sku,
							price,
							brand: product.brand,
							category,
							position: index + 1, // The position of the product that was clicked
						},
					],
				},
			},
			//* probably won't need to redirect after pushing to dataLayer

			// removed callback() because navigate returns Promise<void> and can't be a callable function
			eventCallback: function () {
				// 	document.location = `https://maytronics.sh/product/` + product.slug
				if (callback) return ''
			},
		})
}
/**
 * Call this function when a user adds a product to the cart.
 * @param {object} product An object representing a product.
 * @param {string} list The listing type e.g. 'Search Results'.
 */
// export const onAddToCart = (product, list) => {
// 	const price = Number(floatPrice(product.price / 100))
// 	const category = (product && product.category) || [product.categories && product.categories.title].toString()
// 	window &&
// 		window.dataLayer &&
// 		window.dataLayer.push({
// 			event: 'addToCart',
// 			ecommerce: {
// 				currencyCode: 'AUD',
// 				add: {
// 					actionField: {
// 						list: list, // The list of the product that was clicked
// 					},
// 					products: [
// 						{
// 							name: product.name, // Name or ID is required.
// 							id: product.sku,
// 							price,
// 							brand: product.brand,
// 							category,
// 							quantity: product.quantity, // The position of the product that was clicked
// 						},
// 					],
// 				},
// 			},
// 		})
// }
/**
 * Call this function when a user removes a product from the cart.
 * @param {object} product An object representing a product.
 */
// export const onRemoveFromCart = (product) => {
// 	const price = Number(floatPrice(product.price / 100))
// 	const category = (product && product.category) || [product.categories && product.categories.title].toString()
// 	window &&
// 		window.dataLayer &&
// 		window.dataLayer.push({
// 			event: 'removeFromCart',
// 			ecommerce: {
// 				remove: {
// 					products: [
// 						{
// 							name: product.name, // Name or ID is required.
// 							id: product.sku,
// 							price,
// 							brand: product.brand,
// 							category,
// 							quantity: product.quantity, // The position of the product that was clicked
// 						},
// 					],
// 				},
// 			},
// 		})
// }
/**
 * Call this function when a user clicks on a promotion link. This function uses the event
 * callback datalayer variable to handle navigation after the ecommerce data has been sent
 * to Google Analytics.
 * @param {object} promo An object representing a promotion.
 * @param {Function} callback The navigational function called after event has sent.
 */
export const onPromoClick = (promo: Items, callback: () => string): void => {
	window &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'promotionClick',
			ecommerce: {
				promoClick: {
					promotions: [promo],
				},
			},
			//* probably won't need to redirect after pushing to dataLayer
			eventCallback: function () {
				if (callback) callback()
				return ''
			},
		})
}
