import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BLOCKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import axios from 'axios'
import Tool from '../../../../sea-site-theme/src/svg/tools'
import { Clipboard, MapPin } from 'react-feather'

// for forms
import { StyledForm, HomeButtonRow, HomeButton } from '../../../../sea-site-theme/src/components/form/styles'
import { Formik } from 'formik'
import { InputItems, initialValues, validationSchema } from '../../../../sea-site-theme/src/components/form/utils'
import FormFieldGroup from '../../../../sea-site-theme/src/components/form/FormFieldGroup'
import FormFieldSingle from '../../../../sea-site-theme/src/components/form/FormFieldSingle'
import {
	SupportContactContainer,
	SupportContainer,
	SupportContainerHolder,
	ContactContainer,
	SupportTitle,
	SupportSubTitle,
	ItemsContainer,
	SupportItem,
	SvgContainer,
	ItemInfo,
	ItemTitle,
	ItemSub,
	ContactTitle,
	ContactDescription,
} from './styles'

type SupportEdges = {
	node: {
		title: string
		subtitle: string
		node_locale: string
		items: {
			name: string
			body: {
				raw: string
			}
		}[]
	}
}

type ContactEdges = {
	node: {
		title: string
		description: string | undefined
		items: InputItems
	}
}

interface PageData {
	support: {
		edges: SupportEdges[]
	}
	contact: {
		edges: ContactEdges[]
	}
	location: {
		search: string
	}
}

const SupportContact = (): JSX.Element => {
	const { support, contact } = useStaticQuery<PageData>(graphql`
		query supportContactQuery {
			support: allContentfulFeaturedItems(filter: { contentful_id: { eq: "59UjEaKmVtFzduaw7Lt6yR" } }) {
				edges {
					node {
						title
						subtitle
						node_locale
						items {
							name
							body {
								raw
							}
						}
					}
				}
			}
			contact: allContentfulForm(filter: { contentful_id: { eq: "6D1VD5gGdEys2GwRQKDpWf" } }) {
				edges {
					node {
						title
						description
						endpoint
						node_locale
						items {
							... on ContentfulFormField {
								helpText
								inputAutocomplete
								inputId
								inputName
								inputPlaceholder
								inputRequired
								inputType
								textareaCols
								textareaRows
								icon
								validationText
								width
								options
								node_locale
								label
							}
							... on ContentfulFormGroup {
								title
								items {
									width
									validationText
									textareaRows
									textareaCols
									options
									node_locale
									label
									inputType
									inputRequired
									inputPlaceholder
									inputName
									inputId
									inputAutocomplete
									icon
									helpText
								}
							}
						}
					}
				}
			}
		}
	`)
	const [{ node }] = support.edges
	const [{ node: nodeContact }] = contact.edges

	const { title: contactTitle, description: contactDescription, items: contactItems } = nodeContact
	/** New Object from mapped Array */

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: function Paragraph(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <ItemSub>{children}</ItemSub>
			},
		},
	}

	const icons = [
		{
			icon: <Clipboard color="currentColor" width="45" height="45"></Clipboard>,
		},
		{
			icon: <Tool color="currentColor" width="45" height="45"></Tool>,
		},
		// {
		// 	icon: <MapPin color="currentColor" width="45" height="45"></MapPin>,
		// },
	]

	// const links = ['support/registration', 'shop?query=spare%2520parts', 'support/repairs-and-parts']
	const links = ['https://maytronics.com.au/support/registration', '/contact-us?subject=spare-parts']

	return (
		<SupportContactContainer>
			<SupportContainer>
				<SupportContainerHolder>
					<SupportTitle>{node.title}</SupportTitle>
					<SupportSubTitle>{node.subtitle}</SupportSubTitle>

					<ItemsContainer>
						{node.items.map((item, key: number) => {
							return (
								<SupportItem key={key} to={links[key]}>
									<SvgContainer>{icons[key].icon}</SvgContainer>
									<ItemInfo>
										<ItemTitle>{item.name}</ItemTitle>
										{documentToReactComponents(JSON.parse(item.body.raw), options)}
									</ItemInfo>
								</SupportItem>
							)
						})}
					</ItemsContainer>
				</SupportContainerHolder>
			</SupportContainer>
			<ContactContainer>
				<ContactTitle>{contactTitle}</ContactTitle>
				<ContactDescription>{contactDescription}</ContactDescription>
				<Formik
					initialValues={initialValues(contactItems)}
					validationSchema={validationSchema(contactItems)}
					onSubmit={async (values, { setSubmitting, resetForm }) => {
						try {
							setSubmitting(true)
							const result = await axios.post('/.netlify/functions/mail', {
								formName: 'contact-us',
								...values,
							})
							console.log(result)
							if (!result.data.MessageId) throw new Error('Did not send')
							setSubmitting(false)
							resetForm()
						} catch (error) {
							setSubmitting(false)
							return console.warn(error)
						}
					}}
				>
					{({ isSubmitting, errors, touched, values }) => (
						<StyledForm>
							{contactItems.map((item, index) => {
								if (item.items) {
									const { title, items } = item
									return (
										<FormFieldGroup
											key={(title as string) + index}
											color="#252f3f"
											{...{ items, errors, touched, values }}
										/>
									)
								} else {
									return (
										<FormFieldSingle
											key={item.inputName + index}
											overrideWidth="full"
											color="#252f3f"
											{...{
												item: { ...item, helpText: null },
												errors,
												touched,
												values,
											}}
										/>
									)
								}
							})}
							<p>
								Maytronics may send updates and promotions from time to time. Please see our{' '}
								<a href="https://maytronics.com.au/privacy" target="_blank" rel="noopener noreferrer">
									privacy policy
								</a>{' '}
								for more details.
							</p>
							<HomeButtonRow>
								<HomeButton type="submit" disabled={isSubmitting}>
									Send Message
								</HomeButton>
							</HomeButtonRow>
						</StyledForm>
					)}
				</Formik>
			</ContactContainer>
		</SupportContactContainer>
	)
}

export default SupportContact
